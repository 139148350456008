export enum DisplayFeeType  {
	SHIPMENT = 'SHIPMENT',
	REJECT = 'REJECT',
	OPPOSE = 'OPPOSE'
}

export const isDisplayRejectFeeOrShipmentFeeOrShowAll = (type: DisplayFeeType, data: any): boolean => {
	if (data.isVisibleShipmentFee && data.isVisibleRejectFee) {
		return true
	}
	if (type === DisplayFeeType.SHIPMENT && data.isVisibleShipmentFee) {
		return true
	}
	if (type === DisplayFeeType.REJECT && data.isVisibleRejectFee) {
		return true
	}
	if (type === DisplayFeeType.OPPOSE) {
		return true
	}
	return false
}

export type VatCalculatorResult = {
	vat: number
	totalCodFee: number
}

export const vatCalculator = (codFee: number, absorbVatCOD: boolean): VatCalculatorResult => {
	//
	const merchantCodFee = codFee || 0

	const vat = absorbVatCOD
		? merchantCodFee * (7/100)
		: merchantCodFee - (merchantCodFee * (100/107))
					
	const totalCodFee = absorbVatCOD
		? merchantCodFee
		: (merchantCodFee * (100/107))

	return {
		vat,
		totalCodFee,
	}
}